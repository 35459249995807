import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductForm.css';
import GoogleForm from './GoogleForm'; // ProductForm コンポーネントをインポート


// 環境変数
const API_KEY = 'AIzaSyBNE193W6YrAIn-TKS3l-OmySesn4F_-ZE';
const SPREADSHEET_ID = '1Vn-NBxTJhW6ADXtogqV4is_IVbA9Sd08GBpKFE0Ubv8';
const SHEET_NAME = 'フォームの回答 1';

function ProductForm() {
  const [products, setProducts] = useState([]);

  // スプレッドシートからデータ取得
  const fetchData = async () => {
    try {
      const response = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}!B2:D`, {
        params: { key: API_KEY }
      });
  
      const sheetData = response.data.values;
      const combinedData = sheetData.map(row => {
        return {
          name: row[0], // 商品名
          price: row[2], // 価格
          image: process.env.PUBLIC_URL + '/coffee.jpg' // 画像パス
        };
      });
  
      setProducts(combinedData);
    } catch (error) {
      console.error('Data fetch error:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="product-form-container">

      <GoogleForm /> {/* ProductForm コンポーネントを表示 */}

      <div className="preview-area">
      <div className="preview-header">
              <p style={{ color: 'gray', textAlign: 'center', marginBottom: '20px' }}>
                入力済み商品※画像はイメージです
              </p>
            </div>
            <div className="product-list-container">

        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.image} alt={product.name} />
            <h5>{product.name}</h5>
            <p>{`${product.price}円`}</p> {/* 価格の末尾に円を追加 */}
          </div>
        ))}
      </div>
      </div>

    </div>
  );
}
  
  export default ProductForm;
