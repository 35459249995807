import React from 'react';
import ProductForm from './components/ProductForm'; // ProductForm コンポーネントをインポート
// import ProductForm from './components/ProductFormTest'; // ProductForm コンポーネントをインポート
// import ProductForm from './components/ProductFormTest2'; // ProductForm コンポーネントをインポート

import './App.css'; // 必要に応じてAppのスタイルシートをインポート

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>ECショップ商品情報収集フォーム</h1>
      </header>
      <ProductForm /> {/* ProductForm コンポーネントを表示 */}
    </div>
  );
}

export default App;
