import React, { useState } from 'react';

function GoogleForm() {
    const [submitted, setSubmitted] = useState(false);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

  return (
      <div className="form-container">
      <form 
        action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdajzI_7CkJA-1Cake0387CK7cSvGxuXNsC0R7MU9nEtvbHSg/formResponse"
        method="post" 
        target="hidden_iframe" 
        onSubmit={() => setSubmitted(true)}>
            
            {/* 商品名 */}
          <div className="form-group">
            <label htmlFor="entry.1223447157">商品名・サイズ/重量</label>
            <input className="text-area-field" name="entry.1223447157" id="entry.1223447157" placeholder="商品名・サイズ/重量" onKeyDown={handleKeyDown} />
          </div>

          {/* 商品説明 */}
          <div className="form-group">
            <label htmlFor="entry.1182320788">商品説明</label>
            <textarea className="text-area-field" name="entry.1182320788" id="entry.1182320788" placeholder="商品説明"></textarea>
          </div>

          {/* 価格 */}
          <div className="form-group">
            <label htmlFor="entry.1611151365">価格</label>
            <input className="input-field" type="number" name="entry.1611151365" id="entry.1611151365" placeholder="価格"   onKeyDown={handleKeyDown} />
          </div>

          {/* 在庫状況 */}
          <div className="form-group">
            <label htmlFor="entry.14194902">在庫状況</label>
            <input className="input-field" type="number" name="entry.14194902" id="entry.14194902" placeholder="在庫状況"   onKeyDown={handleKeyDown}/>
          </div>

          {/* 焙煎オプション */}
          <div className="form-group">
            <label htmlFor="entry.1102776420">焙煎オプション</label>
            <select className="select-field" name="entry.1102776420" id="entry.1102776420">
              <option value="なし">なし</option>
              <option value="浅煎り">浅煎り</option>
              <option value="中煎り">中煎り</option>
              <option value="深煎り">深煎り</option>
              <option value="選べる">選べる</option>
            </select>
          </div>

          {/* サイズ/重量
          <div className="form-group">
            <label htmlFor="entry.703565910">サイズ/重量</label>
            <input className="input-field" name="entry.703565910" id="entry.703565910" placeholder="サイズ/重量"  onKeyDown={handleKeyDown}/>
          </div> */}

          {/* ギフトオプション */}
          <div className="form-group">
            <label htmlFor="entry.12742230472">ギフトオプション対応</label>
            <select className="select-field" name="entry.1274230472" id="entry.1274230472">
            <option value="可能">可能</option>
            <option value="不可">不可</option>
            </select>

          </div>

          {/* おすすめの飲み方 */}
          <div className="form-group">
            <label htmlFor="entry.1582020027">おすすめの飲み方</label>
            <textarea className="text-area-field" name="entry.1582020027" id="entry.1582020027" placeholder="おすすめの飲み方"></textarea>
          </div>

          {/* 産地情報 */}
          <div className="form-group">
            <label htmlFor="entry.1544566044">産地情報</label>
            <textarea className="text-area-field" name="entry.1544566044" id="entry.1544566044" placeholder="産地情報"></textarea>
          </div>

          {/* 試飲サービス
          <div className="form-group">
            <label htmlFor="entry.398202289">試飲サービス</label>
            <textarea className="text-area-field" name="entry.1234567890" id="entry.1234567890" placeholder="試飲サービス"></textarea>
            </div> */}


          {/* 追加サービス/キャンペーン */}
          <div className="form-group">
            <label htmlFor="entry.647322031">追加サービス/キャンペーン</label>
            <textarea className="text-area-field" name="entry.647322031" id="entry.647322031" placeholder="追加サービス/キャンペーン"></textarea>
          </div>

          {/* その他特記事項 */}
          <div className="form-group">
            <label htmlFor="entry.356257591">おすすめ度</label>
            <select className="select-field" name="entry.356257591" id="entry.356257591">
            <option value="通常商品">通常商品</option>
              <option value="人気商品">人気商品</option>
              <option value="推していきたい">推していきたい</option>
              <option value="イチオシ">イチオシ</option>
              </select>

          </div>
        

          {/* 送信ボタン */}
          <div className="form-group">
          <button className="submit-button" type="submit">送信</button>
          </div>

        </form>
        <script type="text/javascript">var submitted = false;</script>
        <iframe name="hidden_iframe" id="hidden_iframe" title="Google フォーム送信先" style={{ display: 'none' }} onLoad={() => {
  if (submitted) {
    window.location = 'https://since-around4.com/thankspage/';
  }
}}></iframe>

        </div>
  );
}

export default GoogleForm;
